<template>
  <div ref="cesium" class="h-100 data_wrap">
    <div class="data_top p-10">
      <Icon
        custom="iconfont icon-dituleiditu"
        size="30"
        class="c-h icon_hover"
        @click="getPointData"
      />
      <Icon
        custom="iconfont icon-guiji3"
        size="30"
        class="c-h m-t-10 icon_hover"
        @click="getLineData"
      />
      <Icon
        custom="iconfont icon-book-open-page-variant"
        size="28"
        class="c-h m-t-10 icon_hover"
        @click="course.show = true"
      />
      <Icon
        custom="iconfont icon-quanjingshijiao"
        size="30"
        class="c-h m-t-10 icon_hover"
        @click="panorama.show = true"
      />
      <Icon
        custom="iconfont icon-icon-feixingmanyou"
        size="32"
        class="c-h m-t-10 icon_hover"
        @click="roam.show = true"
      />
      <Icon
        custom="iconfont icon-zhibeizhen"
        size="30"
        class="c-h m-t-10 icon_hover"
        @click="setNavigation"
      />
    </div>
    <div class="data_search">
      <Input
        v-model="pointName"
        placeholder="请输入研学点名称"
        class="w-100 search-input"
        clearable
        search
        @on-search="getPointData"
        enter-button
        v-if="isPoint"
      />
      <Input
        v-model="lineName"
        placeholder="请输入研学路线名称"
        class="w-100 search-input"
        clearable
        search
        @on-search="getLineData"
        enter-button
        v-if="isLine"
      />
    </div>
    <transition name="fade" v-if="isPoint || isLine">
      <div :class="['map_tool', flag ? '' : 'left_close']">
        <div class="table_title p-l-10">
          <span v-if="isPoint">研学点</span>
          <span v-if="isLine">研学路线</span>
        </div>
        <div class="map_total text-c">
          <div class="total_mun" v-if="isPoint">{{ pointTotal }}<span class="f-12 c-white m-l-5">个</span></div>
          <div class="total_mun" v-if="isLine">{{ lineTotal }}<span class="f-12 c-white m-l-5">个</span></div>
          <div class="total_name">
            <span v-if="isPoint"><Icon custom="iconfont icon-jiaoyin" size="16" class="m-r-5" />研学点</span>
          <span v-if="isLine"><Icon custom="iconfont icon-guiji" size="16" class="m-r-5" />研学路线</span>
        </div>
        </div>
        <div class="map_tool_title p-10">
          <Row type="flex" align="middle" class="table_header m-t-10 f-16">
            <Col span="4"></Col>
            <Col span="10">
              <span v-if="isPoint">研学点名称</span>
              <span v-if="isLine">研学路线名称</span>
            </Col>
            <Col span="10"><span>位置</span></Col>
          </Row>
          <!-- 研学点列表 -->
          <div class="table_bg_warp" v-if="isPoint">
            <div
              v-for="(item, index) in pointList"
              :key="index"
              :class="{ 'animate-up': animateUp }"
            >
              <Row type="flex" align="middle" class="table_bg f-14">
                <Col span="4"></Col>
                <Col span="10">
                  <div class="ellipsis">{{ item.pointName }}</div>
                </Col>
                <Col span="10">
                  <div class="ellipsis">
                    {{ renderAddressCode(item.pointAdd) }}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <!-- 研学线列表 -->
          <div class="table_bg_warp" v-if="isLine">
            <div
              v-for="(item, index) in lineList"
              :key="index"
              :class="{ 'animate-up': animateUp }"
            >
              <Row type="flex" align="middle" class="table_bg f-14">
                <Col span="4"></Col>
                <Col span="10">
                  <div class="ellipsis">{{ item.lineName }}</div>
                </Col>
                <Col span="10">
                  <div class="ellipsis">
                    {{ renderAddressCode(item.lineAdd) }}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div class="map_left_close">
          <Icon
            size="40"
            class="map_left_close_icon"
            :type="flag ? 'md-arrow-round-back' : 'md-arrow-round-forward'"
            @click="flag = !flag"
          />
        </div>
      </div>
    </transition>

    <InfoWindow :modal="tool.modal" v-if="tool" />
    <PanoramaModal :modal="panorama" />
    <CourseModal :modal="course" />
    <RoamModal :modal="roam" />
  </div>
</template>

<script>
import { CesiumMap } from "@/libs";
import DrawTool from "@/libs/mapByCesium/DrawTool.js";
import { getAllEntity } from "@/libs/api/point";

import { getAllLineEntity } from "@/libs/api/trace";
import PanoramaModal from "./index/panoramaModal.vue";
import CourseModal from "./index/courseModal.vue";
import RoamModal from "./index/roamModal.vue"
export default {
  components: { PanoramaModal, CourseModal, RoamModal },
  data() {
    return {
      flag: false,
      animateUp: false,
      panorama: {
        show: false,
        title: "全景图"
      },
      course: {
        show: false,
        title: "研学课程"
      },
      roam: {
        show: false,
        title: "研学漫游"
      },
      cesium: null,
      tool: null,
      pageBean: {
        current: 1,
        size: 10,
        query: "",
      },
      pointName: "",
      lineName: "",
      isPoint: false,
      isLine: false,
      pointTotal: 0,
      lineTotal: 0,
      pointList: [],
      lineList: [],
      timer: null,
    };
  },
  methods: {
    //位置信息
    renderAddressCode(arr) {
      if (arr && arr.length) {
        let city = arr[2] ? `${arr[2]}` : "";
        let county = arr[3] ? `${arr[3]}` : "";
        return `${city}${city.length ? "、" : ""}${county}`;
      }
    },

    // 设置向上滚动
    scrollAnimate() {
      if (this.isPoint && this.pointList.length > 11) {
        this.animateUp = true;
        setTimeout(() => {
          this.pointList.push(this.pointList[0]);
          this.pointList.shift();
          this.animateUp = false;
        }, 500);
      }
      if (this.isLine && this.lineList.length > 11) {
        this.animateUp = true;
        setTimeout(() => {
          this.lineList.push(this.lineList[0]);
          this.lineList.shift();
          this.animateUp = false;
        }, 500);
      }
    },

    // 清除数据
    clearData() {
      this.isLine = false;
      this.isPoint = false;
      this.flag = false;
      this.pointList = [];
      this.pointTotal = 0;
      this.lineList = [];
      this.lineTotal = 0;
      this.cesium.viewer.entities.removeAll();
      this.cesium.viewer.dataSources.removeAll();
      this.timer && clearInterval(this.timer);
      this.timer = null;
      if (this.tool.modal.show) {
        Object.assign(this.tool.modal, { show: false });
      }
    },
    getPointData() {
      this.clearData();
      this.isPoint = true;
      this.lineName = "";
      this.getPoint();
    },
    getPoint() {
      getAllEntity({ name: this.pointName }).then(res => {
        if(res.code == "HA0200") {
          this.pointTotal = res.data.length;
          if(res.data.length) {
            this.pointList = res.data.map(item => {
              return {
                ...item,
                name: item.pointName,
                id: item.researchPointId,
                lng: item.pointCoord.lon,
                lat: item.pointCoord.lat,
                ele: item.pointCoord.ele,
                showImage: this.isImg(item.pointImg),
                width: 30,
                height: 30,
                image: "leida.svg",
                type: "point",
              }
            })
            this.cesium.settingViewToChina()
            this.tool.positionMapLayer(this.pointList, { image: "leida.svg" })
            this.timer = setInterval(this.scrollAnimate, 1500);
          } else {
            this.pointList = []
          }
        }
      })
    },
    getLineData() {
      this.clearData();
      this.pointName = "";
      this.isLine = true;
      this.getLine();
    },
    getLine() {
      getAllLineEntity({ name: this.lineName }).then((res) => {
        if (res.code == "HA0200") {
          this.lineTotal = res.data.length;
          if (res.data && res.data.length) {
            this.lineList = res.data.map(item => {
              return {
                ...item,
                name: item.lineName,
                id: item.researchLineId,
                lng: item.lineCenter.lon,
                lat: item.lineCenter.lat,
                ele: item.lineCenter.ele,
                showImage: this.isImg(item.lineImg),
                width: 30,
                height: 30,
                image: "line.svg",
                type: "line",
              }
            })
            this.cesium.settingViewToChina()
            this.tool.positionMapLayer(this.lineList, { image: "line.svg" })
            this.timer = setInterval(this.scrollAnimate, 1500);
          } else {
            this.lineList = []
          }

        }
      });
    },
    // 判断图片是否存在
    isImg(imgUrl) {
      if (imgUrl !== null) {
        if (imgUrl.length > 0 && imgUrl[0] != "") {
          return imgUrl[0];
        }
      } else {
        return `${process.env.VUE_APP_BASE_API}/minio-netdisk/file/8831`;
      }
    },
    setNavigation() {
      this.cesium.setNavigation();
    },
  },
  async mounted() {
    // 加载cesium
    const cesium = await new CesiumMap(this.$refs.cesium, {
      homeButton: false,
      sceneModePicker: false,
      isCredit: true,
      isAnimation: true,
      isTimeline: true,
    });
    this.cesium = cesium;
    this.tool = await new DrawTool(cesium);
  },
  beforeDestroy() {
    this.cesium.removeCesium();
    this.timer && clearInterval(this.timer);
  },
};
</script>

<style lang="less" scoped>
.data_wrap {
  position: relative;
  width: 100%;
  height: 100%;
  .data_top {
    position: absolute;
    top: calc(50% - 100px);
    z-index: 999;
    color: #fff;
    background: rgba(0, 0, 0, 0.2);
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .data_search {
    position: absolute;
    top: 100px;
    right: calc(50% - 120px);
    z-index: 999;
  }
  .icon_hover:hover {
    color: #59e4d8;
  }
  .map_tool {
    z-index: 999;
    position: absolute;
    bottom: 20px;
    color: #fff;
    left: 20px;
    height: calc(~"100% - 110px");
    background: rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2);
    width: 500px;
    transition: all 3s;
    // border: solid 1px #eee;
    border-radius: 0 20px 20px 0;

    .table_title {
      font-size: 14px;
      height: 32px;
      line-height: 32px;
      background: url("/static/images/frame/title-bg.png") no-repeat;
      span {
        background: linear-gradient(to bottom, #cff5ff, #26b1c4);
        -webkit-background-clip: text;
        font-weight: 800;
        color: transparent;
      }
    }
    .map_total {
      height: 80px;
      .total_mun {
        font-size: 36px;
        font-weight: 800;
        font-family: Arial, Helvetica, sans-serif;
        color: #ace9f1;
      }
    }
    .map_tool_title {
      overflow: hidden;
      height: calc(100% - 120px);
    }
    .table_header {
      span {
        font-weight: bold;
        color: #fdbf46;
        background-image: -webkit-linear-gradient(
          bottom,
          #f9ff46,
          #fdffca,
          #ffffff
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .table_bg_warp {
      overflow: hidden;
      .table_bg {
        width: 100%;
        height: 40px;
        background-image: url("/static/images/frame/tablebg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin: 20px 0;
        // position: relative;
      }
    }

    .animate-up {
      transition: all 0.5s ease-in-out;
      transform: translateY(-60px);
    }
  }
  .map_left_close {
    position: absolute;
    top: calc(50% - 60px);
    left: 500px;
    width: 60px;
    height: 60px;
    border: solid 1px #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 70px;
    .map_left_close_icon:hover {
      color: #efefef;
    }
  }
  .left_close {
    left: -500px;
  }
}
</style>
<style lang="less">
.data_search {
  .search-input input {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.2);
    border: none;
}
}
</style>>

<template>
  <Modal
    v-model="modal.show"
    :title="modal.title"
    width="1200"
    footer-hide
    class-name="roam-modal"
  >
    <Row :gutter="10">
      <Col span="6">
        <Input
          v-model="param.query"
          placeholder="请输入研学漫游名称"
          clearable
        />
      </Col>
      <Col span="6">
        <Button type="primary" @click="getData()">查询</Button>
      </Col>
    </Row>
    <Table
      border
      :columns="cols"
      :data="table.records"
      ref="table"
      class="m-t-10"
      :height="500"
    ></Table>
    <Page
      :page="param.current"
      @on-change="getData"
      :page-size="param.size"
      :total="table.total"
      show-total
      class="text-r m-t-10"
    />
  </Modal>
</template>

<script>
import { getRoam } from "@/libs/api/roam";
export default {
  props: {
    modal: { type: Object },
  },
  data() {
    const _this = this
    return {
      param: {
        current: 1,
        size: 10,
        order: "name",
      },
      table: {},
      cols: [
        {
          title: "序号",
          type: "index",
          key: "index",
          width: 70,
          align: "center",
        },
        { title: "研学漫游名称", key: "name", width: 200, tooltip: true },
        {
          title: "研学漫游类型",
          key: "type",
          render(h, w) {
            let roam_type = "其他";
            switch (w.row.type) {
              case "point":
                roam_type = "研学点";
                break;
              case "line":
                roam_type = "研学路线";
                break;
              case "panorama":
                roam_type = "研学全景";
                break;
            }
            return h("span", roam_type);
          },
        },
        {
          title: "研学漫游内容",
          key: "content",
          tooltip: true,
          ellipsis: true,
          render(h, w) {
            return h("span", w.row.content && w.row.content.join(","));
          },
        },
        {
          title: "操作",
          width: 100,
          render(h, w) {
            const btns = [];
            if (w.row.content) {
              btns.push(
                h(
                  "Button",
                  {
                    props: { type: "info", size: "small" },
                    on: {
                      click() {
                        _this.$router.push({
                          path: "/dataV/index/roam",
                          query: { id: w.row.id, isTitle: 1 },
                        });
                      },
                    },
                  },
                  "预览"
                )
              );
            }
            return btns;
          },
        },
      ],
    };
  },
  methods: {
    getData(page) {
      Object.assign(this.param, { current: page ? page : 1 });
      getRoam(this.param).then((res) => {
        res.code == "HA0200"
          ? (this.table = res.data)
          : this.$Message.error(res.msg || "查询错误");
      });
    },
  },
  mounted() {
    this.$watch("modal.show", (show) => {
      if (show) {
        this.getData();
      }
    });
  },
};
</script>

<style lang="less" scoped>

</style>

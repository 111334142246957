<template>
  <Modal
    v-model="modal.show"
    :title="`研学课程--(${modal.data.fileName})`"
    footer-hide
    class-name="panorama-view"
    fullscreen
  >
    <iframe
      :ref="uuid"
      :src="iframeSrc"
      class="research_course_iframe"
    ></iframe>
  </Modal>
</template>

<script>
import { util } from "@/libs";
export default {
  props: {
    modal: { type: Object },
  },
  data() {
    return {
      uuid: util.uuid,
      iframeSrc: "",
    };
  },
  methods: {},
  mounted() {
    this.$watch("modal.show", (show) => {
      if (show) {
        this.iframeSrc = `${process.env.VUE_APP_BASE_EPUB}?fileUrl=${process.env.VUE_APP_BASE_API}/minio-netdisk/file/e/${this.modal.data.id}.epub`;
      }
    });
  },
};
</script>

<style lang="less">
.panorama-view {
  .ivu-modal-body {
    padding: 0;

  }
  .research_course_iframe {
    width: 100%;
    height: 99%;
    border: none;

  }
}
</style>

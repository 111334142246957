import { request } from '@/libs'

/**
 * 研学课程
 */

// 分页获取研学课程列表
export function getCourse(params) {
  return request.get('/minio-netdisk/folders/page/general', { params })
}

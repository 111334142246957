<template>
  <Modal v-model="modal.show" :title="modal.title" width="1200" footer-hide class-name="panorama-modal">
    <Row :gutter="10">
      <Col span="6">
        <Input v-model="param.query" placeholder="请输入全景图名称" clearable />
      </Col>
      <Col span="6">
        <Button type="primary" @click="getData()">查询</Button>
      </Col>
    </Row>
    
    <Row type="flex" class="m-t-10 no-img" v-if="table.records && table.records.length">
      <Col span="4" v-for="(item, index) in table.records" :key="index" class="m-t-10 m-b-10">
        <div class="text-c">
          <img src="/static/images/frame/panorama_2.png" class="title-img c-h" @click="getView(item)">
          <!-- <img :src="item.url || '/static/images/frame/noImg.jpg'" class="title-img c-h" @click="getView(item)" v-if="!item.type" />
          <img :src="renderImg(item.urlList)" class="title-img c-h" @click="getView(item)" v-else /> -->
          <div class="title ellipsis m-t-10">{{ item.name }}</div>
        </div>
      </Col>
    </Row>
    <div class="text-c m-t-10 no-img p-t-30" v-else>暂无数据</div>
    <Page :page="param.current" @on-change="getData" :page-size="param.size" :total="table.total" show-total class="text-r m-t-10" />
    <PanoramaView :modal="detail" />
  </Modal>
</template>

<script>
import { getPanorama } from '@/libs/api/panorama'
import { util } from '@/libs'
import PanoramaView from "./panoramaView.vue"
export default {
  props: {
    modal: { type: Object },
  },
  components: { PanoramaView },
  data() {
    return {
      param: {
        current: 1,
        size: 12,
        order: 'name',
      },
      table: {},
      detail: {
        show: false,
        data: {}
      }
    }
  },
  methods: {
    getView(item) {
      Object.assign(this.detail, { show: true, data: util.deepcopy(item) })
    },
    renderImg(urlList) {
      if(urlList && urlList.length) {
        return urlList[0]
      } else {
        return '/static/images/frame/noImg.jpg'
      }
    },
    getData(page) {
      Object.assign(this.param, { current: page ? page : 1 })
      getPanorama(this.param).then(res => {
          res.code == "HA0200" ? this.table = res.data : this.$Message.error(res.msg || "查询错误")
      });
    }
  },
  mounted() {
    this.$watch("modal.show", (show) => {
      if(show) {
        this.getData()
      }
    })
  }
}
</script>

<style lang="less" scoped>
.panorama-modal {
  .title-img {
    width: 150px;
    height: 100px;
  }
  .title {

  }
  .no-img {
    height: 400px;
  }
}
</style>

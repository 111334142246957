<template>
  <Modal
    v-model="modal.show"
    :title="modal.title"
    width="1200"
    footer-hide
    class-name="panorama-modal"
  >
    <Row :gutter="10">
      <Col span="6">
        <Input
          v-model="param.fileName"
          placeholder="请输入研学课程名称"
          clearable
        />
      </Col>
      <Col span="6">
        <Button type="primary" @click="getData()">查询</Button>
      </Col>
    </Row>
    <Row type="flex" class="m-t-10 no-img" v-if="table.data">
      <Col
        span="4"
        v-for="(item, index) in table.data"
        :key="index"
        class="m-t-10 m-b-10"
      >
        <div class="text-c">
          <img
            src="/static/images/frame/epub.jpg"
            class="title-img c-h"
            @click="getView(item)"
          />
          <Tooltip
            :content="item.fileName"
            max-width="400"
            class="w-100 m-t-10"
          >
            <div class="title ellipsis">{{ item.fileName }}</div>
          </Tooltip>
          <div class="f-14">{{ item.nowAuthor }}</div>
          <div class="f-14">{{ item.updateTime.split("T")[0] }}</div>
        </div>
      </Col>
    </Row>
    <div class="text-c m-t-10 no-img p-t-30" v-else>暂无数据</div>
    <Page
      :page="param.current"
      @on-change="getData"
      :page-size="param.pageSize"
      :total="table.total"
      show-total
      class="text-r m-t-10"
    />
    <CourseView :modal="detail" />
  </Modal>
</template>

<script>
import { getCourse } from "@/libs/api/course";
import { util } from "@/libs";
import CourseView from "./courseView.vue";
export default {
  props: {
    modal: { type: Object },
  },
  components: { CourseView },
  data() {
    return {
      param: {
        pageNum: 1,
        pageSize: 12,
        // 2164为研学课程文件夹id
        folderId: "2164",
        fileName: "",
        fileType: "application/epub+zip",
      },
      table: {},
      detail: {
        show: false,
        data: {},
      },
    };
  },
  methods: {
    getView(item) {
      Object.assign(this.detail, { show: true, data: util.deepcopy(item) });
    },
    getData(page) {
      Object.assign(this.param, { pageNum: page ? page : 1 });
      getCourse(this.param).then((res) => {
        res.code == "HA0200"
          ? (this.table = res)
          : this.$Message.error(res.msg || "查询错误");
      });
    },
  },
  mounted() {
    this.$watch("modal.show", (show) => {
      if (show) {
        this.getData();
      }
    });
  },
};
</script>

<style lang="less" scoped>
.panorama-modal {
  .title-img {
    width: 100px;
    height: 120px;
  }
  .title {
  }
  .no-img {
    min-height: 400px;
  }
}
</style>
